// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compile

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require("jquery")


// Tailwind CSS
import "stylesheets/application"


// FIRST UPPY INSTANCE FOR MULTIPLE UPLOADS AT A TIME
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const XHRUpload = require('@uppy/xhr-upload');
// const Facebook = require('@uppy/facebook');
const ImageEditor = require('@uppy/image-editor')

// ! Should find a way to make these imports work and can then remove uppy.scss bloated file !!

// require('@uppy/core/dist/style.css')
// require('@uppy/dashboard/dist/style.css')
// import '@uppy/image-editor/dist/style.css'

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))
})

// Reuseable methods for both instances of uppy
function appendUploadedFile(element, file, field_name) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.body.signedId)

  element.appendChild(hiddenField)
}

function create_UUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

// First uppy instance (used for multiple photo uploads to poss)
function setupUppy(element) {
  let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
  let field_name = element.dataset.uppy
  trigger && trigger.addEventListener('click', (event) => event.preventDefault())

  let uppy = Uppy({
    id: '1',
    autoProceed: false,
    allowMultipleUploads: true,
    logger: Uppy.debugLogger,
    restrictions: {
      maxNumberOfFiles: 20,
      maxFileSize: 10000000,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*']
    },

    onBeforeFileAdded: (currentFile) => {
      const modifiedFile = {
        ...currentFile,
        name:  create_UUID() + Date.now()
      }
      return modifiedFile
    }
  })

  uppy.use(XHRUpload, {
    endpoint: 'https://www.tracetaiwan.com/upload_images',
    // endpoint: 'http://localhost:3000/upload_images',
    formData: true,
    bundle: false,
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    }
  })

  uppy.use(Dashboard, {
    trigger: trigger,
    note: 'Images only, 10mb or less per image. Max 20 images at once',
    closeAfterFinish: false,
  })

  // uppy.use(Facebook, {
  //   target: Dashboard,
  //   companionUrl: 'https://uppy-companion-server.herokuapp.com',
  // })

  uppy.on('complete', (result) => {
    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
    })
  })

}

// SECOND UPPY INSTANCE (used for single photo uploads as user profile pic)

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-uppy]').forEach(element => setupUppyProfile(element))
})

function setupUppyProfile(element) {
  let profileTrigger = element.querySelector('[data-behavior="uppyProfile-trigger"]');
  let field_name = element.dataset.uppy;
  profileTrigger && profileTrigger.addEventListener('click', (event) => event.preventDefault())

  let uppyProfile = Uppy({
    id: '2',
    autoProceed: false,
    allowMultipleUploads: true,
    logger: Uppy.debugLogger,
    restrictions: {
      maxNumberOfFiles: 1,
      maxFileSize: 10000000,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*']
    },

    onBeforeFileAdded: (currentFile) => {
      const modifiedFile = {
        ...currentFile,
        name:  create_UUID() + Date.now()
      }
      return modifiedFile
    }
  })

  uppyProfile.use(XHRUpload, {
    endpoint: 'https://www.tracetaiwan.com/upload_images',
    // endpoint: 'http://localhost:3000/upload_images',
    formData: true,
    bundle: false,
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    }
  })

  uppyProfile.use(Dashboard, {
    trigger: profileTrigger,
    note: 'Images only, 10mb or less per image',
    closeAfterFinish: false,
  })

  uppyProfile.use(ImageEditor, {
    target: Dashboard,
    quality: 0.8,
    cropperOptions: {
      viewMode: 1,
      background: false,
      autoCropArea: 1,
      responsive: true
    },
    actions: {
      revert: true,
      rotate: true,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: true,
      cropWidescreen: true,
      cropWidescreenVertical: true
    }
  })

  // uppy.use(Facebook, {
  //   target: Dashboard,
  //   companionUrl: 'https://uppy-companion-server.herokuapp.com',
  // })

  uppyProfile.on('complete', (result) => {
    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
    })
  })
}


